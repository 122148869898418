*{ padding: 0px; margin: 0px; box-sizing: border-box }

html, body {
	color: #222;
	font-family: 'Lato', sans-serif;
	font-size: 14px;
	margin: 0px;
	padding: 0px;
	width: 100%;
	height: 100%;
	line-height: normal;
}

a {
	text-decoration: none;
	color: #222;
}

body {
	background: #E5E5E5;
	display: flex;
}

.clr {
	clear: both;
}